.buy-page-parent{
    width: 100%;
    height: 100%;
    background-color: white;
    overflow: scroll;
}

.buy-page-parent::-webkit-scrollbar{
  display: none;
}
.property-page-container{
    margin: 2% 2%;
    display: flex;
    flex-direction: column;
}
  #property-file-input{
    border-color: black;
  }
  .edit-form-image{
    width: 75px;
    height: 75px;
  }
  .image-dlt-icon{
    height: 20px;
    width: 20px;
    position: absolute;
    right: 5px;
    top: 0;
    &:hover{
      cursor: pointer;
    }
  }
  .card-layer{
    position: relative;
    &:hover{
      .delete-button{
        display: block;
      }
      .edit-button{
        display: block;
      }
    }
  }

  .delete-button{
    display: none;
    z-index: 300;
    width: 32px;
    height: 32px;
position:absolute;
right: 10px;
top: 10px;
&:hover{
  border-radius: 8px;
  cursor: pointer;
  scale: 1.03;
  background-color: rgb(59, 53, 46);
}
}
.edit-button{
  display: none;
  z-index: 300;
  width: 32px;
  height: 32px;
position:absolute;
right: 50px;
top: 10px;
&:hover{
border-radius: 8px;
cursor: pointer;
scale: 1.03;
background-color: rgb(59, 53, 46);
}
}
.edit-image-container{
  position: relative;
}
.commun_input {
  padding: 8px 0px;
  border: none;
  border-bottom: 1px solid rgb(147, 147, 147);
  font-size: 18px;
  width: 100%;
}

.commun_input:is(:focus) {
  color: var(--accent-blue);
  caret-color: var(--accent-blue);
  outline: none;
}

.red-label{
  color: red;
  font-weight: 600;
}

.form-gap{
  margin-top: 30px !important;
  font-size: 14px;
}