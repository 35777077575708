.np-icon {
  transition: 0.3s ease;
  width: 50px;
  height: 50px;
  border-radius: 50%;

}

.np-icon:is(:hover) path {
  stroke: var(--accent-blue);
}

.np-icon path {
  stroke: black;
  stroke-width: 1.5;
  stroke-miterlimit: 10;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  transition: all 0.3s ease;
}

.navigation_container button:is(:hover, :focus) {
  outline: none;
}
.navigation_container button:is(:hover, :focus) .np-icon path {
  stroke: var(--accent-blue);
}

.navigation_container button:is(:hover, :focus) .icon-f .arrow {
  animation: prev 0.6s linear;
}

.navigation_container button:is(:hover, :focus) .np-icon {
  box-shadow: 0px 0px 5px var(--accent-blue);
}

.navigation_container button:is(:hover, :focus) .icon-s .arrow {
  animation: next 0.6s linear;
}

@keyframes prev {
  0% {
    transform: translateX(0px);
  }

  25% {
    transform: translateX(-5px);
  }

  50% {
    transform: translateX(0px);
  }

  75% {
    transform: translateX(-5px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes next {
  0% {
    transform: translateX(0px);
  }

  25% {
    transform: translateX(5px);
  }

  50% {
    transform: translateX(0px);
  }

  75% {
    transform: translateX(5px);
  }

  100% {
    transform: translateX(0px);
  }
}