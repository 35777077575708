@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.adminlayoutparent {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100dvh;
}
  
:root {
  /* Colors */
  --primary-light: white;
  --primary-dark: #0A3D62;
  --accent-blue: #80adbb;
  --accent-pink: #ce9d9d;
  --accent-yellow: #dfd9ab;
  --accent-secondary-yellow: #e7bd00;
  --lightened-bgcolor: #0A3D62;

  /*Sizes*/
  --lrg-size: 56px /* or 4rem */;
  --mdm-L-size: 24px /* or 1.725rem */;
  --mdm-size: 18px /* or 1.125rem */;
  --sml-size: 12px /* or 0.75rem */;
}

* {
  font-family: "Poppins", sans-serif;
}

* {
  font-family: "Poppins", sans-serif;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background: var(--primary-light);
}

.main_container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100px minmax(calc(100dvh - 203px), auto);
}

.properties_main{
  height: calc(100dvh - 190px);
  padding: 20px;
  display: grid;
  grid-template-columns: 35vh 1fr;
  grid-template-rows: 100% auto;
  grid-column-gap: 30px;
  grid-row-gap: 20px;
}

.properties_main .rb_btn{
  padding: 0;
  padding-inline: 10px;
}

.divider{
  height: calc(100dvh - 125px);
}

h1{
  margin: 0;
  font-size: var(--lrg-size);
  font-weight: 500
}


.rb_btn{
  padding: 3px 10px;
  border-radius: 5px;
  border: 2px solid #6e6e6e;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 10;
  transition: 0.2s all ease;
  background-color: rgb(91, 145, 166);
}

.rb_btn::before{
  position: absolute;
  content: '';
  background-color: var(--accent-blue);
  width: 100%;
  height: 100%;
  top: 50%;
  z-index: -1;
  left: 0%;
  transform: translate(-150%, -50%);
  transition: .2s all ease;
}
.rb_btn:is(:hover, :focus)::before{
  transform: translate(0%, -50%);
}

.rb_btn:is(:hover, :focus){
  outline: none;
  color: white;
  box-shadow: rgb(49, 49, 49) 0px 10px 10px -3px;
  transform: translateY(-1px);
}

.divider_spacer{
  margin-top: auto;
  height: 100px;
  width: 500px;
  margin-left: auto;
}


.properties_content .loader-container{
  height: 100%;
}

@media all and (max-width:900px){
.main_container{
  grid-template-rows: 100px auto;
}

.properties_main{
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  grid-row-gap: 30px;
  height: calc(100dvh - 100px);
}

.divider{
  height: calc(100dvh - 100px);
}
}

@media all and (max-width:450px) {
  .properties_main{
    padding: 10px;
    height: calc(100dvh - 100px);
    grid-row-gap: 10px;
  }
}




@media all and (max-width:900px) and (orientation: landscape) and (max-height: 400px){
  .properties_main{
    height: auto;
  }

  .cat_card_container{
    height: auto!important;
    width: 50%;
  }
  
}