.cat_card_container {
  height: 48vh;
  /* flex-grow: 1; */
  background-color: var(--primary-dark);
  border-radius: 10px;
  display: grid;
  grid-template-rows: 65% 17% 17%!important;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  color: white;
  position: relative;
  transition: 0.3s all ease;
  cursor: pointer;
}

.auto_width {
  width: 30% !important;
}

.no_third_layer{
  grid-template-rows: 65% 17% 17%!important;
}

.cat_card_container:hover{
  transform: scale(1.01);
}




.cat_card_container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px ;
}

.primary_info_section,
.secondary_info_section {
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-inline: 10px;
  column-gap: 10px;
  position: relative;
  flex-wrap: wrap;
}

.primary_info_section p {
  font-weight: 600!important;
}

.secondary_info_section::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 95%;
  top: 0;
  background-color: var(--primary-light);
}
.secondary_info_section > * {
  display: flex;
  align-items: center;
  gap: 10px;
}
.location {
  display: flex;
  align-items: center;
  gap: 10px;
}

.location p {
  margin: 0;
}

.price {
  font-weight: 500;
  word-break: break-all;
}


.price span{
  margin-left: 5px;
  font-size: 13px;
}
@media all and  (max-width: 1040px) {
  .auto_width {
    width: 46% !important;
  }
  .properties_content {
    gap: 20px !important;
  }
}
@media all and  (max-width: 500px) {
  .auto_width {
    width: 85% !important;
  }
}

@media all and  (max-width: 700px) {
  .secondary_info_section p{
    font-size: clamp(0.8125rem, 0.6676rem + 0.7246vw, 1.4375rem);
  }
}