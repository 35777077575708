/* lnews.css */
.latest-news-section {
  padding: 60px 0;
  z-index: 1;
  background-color: #f8f9fa;
}

.latest-news-header {
  font-size: calc(var(--lrg-size) - 20px) !important;
  color: var(--primary-dark);
  font-weight: 700 !important;
  width: 85%;
  margin: 0 auto 60px auto !important;
}

.news-card {
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.news-card:hover {
  transform: translateY(-10px);
}

.news-thumbnail {
  height: 200px;
  object-fit: cover;
  border-bottom: 2px solid #ddd;
}

.news-card .card-body {
  padding: 1.5rem;
}

.news-card .card-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--primary-dark);
}

.news-card .card-text {
  font-size: 1rem;
  color: #6c757d;
  line-height: 1.5;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .latest-news-header {
    font-size: 2rem;
  }
  .news-card .card-body {
    padding: 1rem;
  }
}

@media (max-width: 550px) {
  .latest-news-header {
      text-align: start !important;
      font-size: calc(var(--lrg-size) - 25px) !important;
      margin-bottom: 40px !important;
  }
}