.offplan_wrapper {
  width: 85%;
  margin: 0 auto;
  margin-top: 5dvh;
  min-height: 100dvh;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow: hidden;
}

.offplan_wrapper .prev-button i,
.offplan_wrapper .next-button i {
  transition: .1s all ease;
  font-size: 50px;
  position: relative;
  width: 71px;
  height: 90px;
  color: rgb(124, 114, 114);
}

.offplan_wrapper .prev-button i::before ,
.offplan_wrapper .next-button i::before  {
  z-index: 10;
  transition: .2s all ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.offplan_wrapper .prev-button i:after ,
.offplan_wrapper .next-button i:after   {
  transition: .2s all ease;
  content: "";
  display: block;
  background: rgba(255, 255, 255, 0.407);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.92);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 77%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.offplan_wrapper .prev-button:is(:hover, :focus-visible) i:after,
.offplan_wrapper .next-button:is(:hover, :focus-visible) i:after  {
  background: rgba(25, 170, 238, 0.407);
}

.offplan_wrapper .prev-button:is(:hover, :focus-visible) i,
.offplan_wrapper .next-button:is(:hover, :focus-visible) i{
  color: white;

}

.off_plan_container {
  display: grid;
  grid-template-rows: 60dvh minmax(20dvh, auto) minmax(35dvh, auto) minmax(30dvh, auto) minmax(
      30dvh,
      auto
    );
  grid-template-columns: 100%;
}

.off_plan_container .properties_content{
  overflow: unset;
}

.off_plan_container .in_touch_content{
  gap: 5vh;
}

.off_plan_container  #footer > *{
  padding-inline: 2.5%;
}
.proptitle {
  color: black;
  font-size: 45px !important;
  font-weight: 400 !important;
}
.description_offplan {
  border-radius: 20px;
  width: 90%;
  margin: 0 auto;
  background-color: white;
  position: relative;
  margin-top: -40px;
  padding: 20px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 20px !important;
}

.off_plan_container .properties_content .cat_card_container {
  width: 30%;
}

.description_offplan h1 {
  font-size: 30px !important;
}

.description_offplan h3 {
  font-size: 22px !important;
  font-weight: medium !important;
}

.description_offplan div {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.description_offplan p {
  word-break: break-word;
  /* font-size: 19px; */
  text-align: justify;
  line-height: 30px;
  width: 90%;
  margin: 0 auto;
}
.carousel-con {
  display: flex;
  align-items: stretch;
  height: 100%;
  position: relative;
}

.carousel-con button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  appearance: none;
  background: none;
  border: none;
  outline: none;
}

.carousel-con button:focus .np-icon path {
  stroke: black !important;
  stroke-width: 5;
}

.carousel-con .np-icon:is(:hover) path {
  stroke: black !important;
  stroke-width: 5;
}
.carousel_off {
  display: flex;
  overflow: hidden;
  scroll-behavior: smooth;
  width: 100%;
  scroll-snap-type: x mandatory;
}

.carousel-slide_off {
  height: 100%;
  flex: 0 0 100%;
  display: flex;
  /* padding: 10px; */
  scroll-snap-align: center;
  box-sizing: border-box;
}

.carousel-slide_off img {
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
}

.prev-button {
  left: 20px;
}

.next-button {
  right: 20px;
}

.carousel_off::-webkit-scrollbar {
  display: none;
}

.offplan_features {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5vh;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-top: 40px;
  flex-wrap: wrap;
  /* border-top: 1px solid gray; */
}

.feature {
  background-color: var(--lightened-bgcolor);
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 46vh;
  height: 19dvh;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  border: 5px solid var(--accent-blue);
  color: var(--accent-blue);
}
.locbut {
  border: 2px solid #ce9d9d;
}
.av {
  border: 5px solid #dfd9ab;
  color: #dfd9ab;
}
.av i {
  color: #dfd9ab !important;
}
.loc {
  border: 5px solid #ce9d9d;
  color: #ce9d9d;
}
.loc i {
  color: #ce9d9d !important;
}

.loc .rb_btn::before {
  background-color: #ce9d9d !important;
}
.feature::after {
  content: "";
  position: absolute;
  inset: 0;
}

.proptitle {
  font-size: calc(var(--lrg-size) - 25px) !important;
  color: var(--primary-dark);
  font-weight: 700 !important; 
  text-align: center;
  margin-top: 30px !important;
}
.feature h3,
.feature span {
  width: 70%;
  position: relative;
  z-index: 2;
}
.feature h3 {
  margin: 0;
}

.feature i {
  position: absolute;
  font-size: 150px;
  bottom: -50px;
  right: -50px;
  z-index: 0;
  color: var(--accent-blue);
}

.offplan_properties {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 50px;
  position: relative;
}
.offplan_properties::-webkit-scrollbar {
  display: none;
}

.offplan_properties h1 {
  padding-top: 50px;
  z-index: 1;
  position: sticky;
  top: 0;
  padding-bottom: 20px;
  border-bottom: 1px solid gray;
}

.offplan_properties h1::before {
  top: 100.5%;
  content: "";
  position: absolute;
  height: 50px;
  width: 100%;
}

.offplan_map {
  background-color: var(--accent-pink);
  height: 60dvh;
  width: 80%;
  margin: 0 auto;
  margin-top: 10dvh;
  margin-bottom: 10dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
}

.buttonag {
  font-family: unset;
  font-size: unset;
  line-height: 1;
  padding: 10px;
  background-color: white;
  color: var(--lightened-bgcolor);
  font-weight: bold;
}

.offplan_stats {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  gap: 20px !important;
}

.offplan_stats_item {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 30% !important;
  border-bottom: 2px solid var(--primary-dark);
  gap: 0 !important;
}

.offplan_stats_item h6 {
  font-size: 13px !important;
}

.offplan_stats_item span {
  font-size: 15px !important;
}

@media all and (max-width: 950px) {
  .offplan_stats_item {
    width: 45% !important;
  }

  .off_plan_container .properties_content .cat_card_container {
    width: 47%; 
  }
}

@media all and (max-width: 700px) {
  .offplan_stats_item h6 {
    font-size: 12px !important;
  }
  .offplan_stats_item span {
    font-size: 14px !important;
  }

  .description_offplan h3 {
    font-size: 20px !important;
  }

  .description_offplan div {
    gap: 20px;
  }

  .description_offplan p {
    font-size: 14px !important;
  }
}

@media all and (max-width: 600px) {
    .offplan_stats_item h6 {
      font-size: 11px !important;
    }
    .offplan_stats_item span {
      font-size: 13px !important;
    }

    .offplan_stats {
      gap: 20px 10px !important;
    }

    
  .off_plan_container .properties_content .cat_card_container {
    width: 95%;
  }
}

@media all and (max-width: 450px) {
  .offplan_stats_item {
    width: 100% !important;
  }
}

@media all and (max-width: 500px) {

  .offplan_wrapper  {
    width: 100%;
  }
  .offplan_features {
    padding-inline: unset;
  }

  .offplan_features .feature {
    width: 80%;
    padding: 20px;
  }

  .description_offplan p {
    width: 100%;
    font-size: 15px;
  }

  .offplan_properties {
    width: 90%;
  }

  .offplan_map {
    width: 95%;
    height: 50dvh;
  }
}


@media all and (max-width: 410px) {
  .feature i {
    font-size: 130px;
    bottom: -40px;
    right: -40px;
  }
}

.view-more-btn-offplan {
  position: absolute !important;
  top: 30px !important;
  right: 30px !important;
  background-color: var(--lightened-bgcolor) !important;
  border-radius: 10px !important;
  padding: 5px 15px !important;
  transition: .1s all ease;
}

.view-more-btn-offplan:hover {
  background-color: var(--accent-blue) !important;
}

.cat_card_container {
  width: 300px;
}

