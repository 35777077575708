.dashboard-loader-container {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: rgba(255, 255, 255, 0.9); Optional background for better visibility */
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.loader-container p {
  text-align: center;
  margin: 0px;
}

.loader-container .first_loading_p {
  margin-top: 20px !important;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensures no cropping */
  animation: rotate 2s linear infinite;
}

/* Rotation animation */
@keyframes rotate {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.loader-container p {
  font-size: 16px;
  color: #333;
}
