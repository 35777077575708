.l_form_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh ;
}

.l_form {
  width: 600px;
  min-height: 700px;
  background-color: white;
  display: flex;
  flex-direction: column;
  animation: pop 0.3s linear;
  padding: 20px;
  display: flex;
  box-sizing: border-box;
  gap: 30px;
  flex-direction: column;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.title_container h4 {
  margin: 0;
  padding-bottom: 10px;
}
.l_form img {
  width: 250px;
  margin: 0 auto;
  mix-blend-mode: difference;
}

.l_form input {
  padding: 8px 0px;
  border: none;
  border-bottom: 1px solid rgb(147, 147, 147);
  font-size: 18px;
  width: 100%;
}

.l_form input::placeholder{
  font-family: 'Playfair Display', 'Open Sans', serif, sans-serif;
}

.l_form input:is(:focus) {
  color: var(--accent-blue);
  caret-color: var(--accent-blue);
  outline: none;
  /* border-color: var(--accent-blue); */
}

 .label{
  position: relative;
}


.label:focus-within::before {
  content: '';
  transform: scaleX(1);
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  animation: lol .2s linear;
  background-color: var(--accent-blue);
}

@keyframes lol {
  0%{
    width: 0px;
  }
  100%{
    width: 100%;
  }
}

.l_form input:is(:focus)::placeholder{
  color: var(--accent-blue);
}
 .label input:focus + .label::before{
  width: 100%;
}

 .label:focus-within i{
  color: var(--accent-blue);
}
.titles_wrap {
  display: flex;
  gap: 25px;
}

.titles_wrap input{
  position: relative;
  appearance: none;
  border-bottom: none;
}

.titles_wrap input[type='radio']::after,
.titles_wrap input[type='radio']::before{
  transition: .4s all ease;
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.titles_wrap input[type='radio']::after{
  width: 20px;
  height: 20px;
  background-color: white;
  border: 2px solid rgb(99, 99, 99);
}

.titles_wrap input[type='radio']::before{
  visibility: hidden;
  width: 13px;
  height: 13px;
  z-index: 10;
  transform: translate(-50%, -50%) scale(0);
  background-color: var(--accent-blue);
}


.titles_wrap input[type='radio']:checked:after{
  border-color: var(--accent-blue);
}


.ms_container input[type='radio']:checked::after{
  border-color: var(--accent-pink);
}

.ms_container input[type='radio']:checked::before{
  background-color: var(--accent-pink);
}

.titles_wrap input[type='radio']:checked::before{
  visibility: visible;
  transform: translate(-50%, -50%) scale(1);
}



.l_form .filtering {
  font-size: 18px;
}

.l_form .filtering .main_span{
  color: black;
}

.l_form .filtering .main_span:hover{
  color: var(--accent-blue);
}

.l_form .filtering:focus {
  outline: none;
}

.l_form .filtering:focus .main_span {
  border: 1px solid var(--accent-blue);
  color: var(--accent-blue);
}

.ms_container,
.mr_container {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.titles_wrap i{
  font-size: 25px;
  transition: .4s all ease
}


.label i{
  font-size: 18px;
  color: gray;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

#dateInput{
  color: rgb(72, 72, 72);
}
@keyframes pop {
  0% {
    scale: 0;
  }

  100% {
    scale: 1;
  }
}


.submit_button{
  margin-top: auto;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  border: none;
  background-color: var(--accent-blue);
  color: white;
  font-size: 18px;
  cursor: pointer;
  transition: .1s all ease;
}

.submit_button:is(:hover, :focus){
  background-color: #517d8b;
}

.l_form_wrapper .loader-container p{
  color: white;
}

.l_form_wrapper .inq_sent{
  color: white;
  width: 350px;
  height: 150px;
  font-size: 23px;
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 0;
  font-weight: bold;
  background-color: rgb(60, 60, 60);
  transition: .3s all ease;
  box-shadow: 0px 0px 6px rgba(255, 255, 255, 0.25);
  animation: scalup .5s  alternate;
}


@keyframes scalup {
  0%{
    transform: scale(0);
    opacity: 0;
  }

  100%{
    transform: scale(1);
    opacity: 1;
  }

}


.rb_title {
  padding-bottom: 5px;
}