@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.privacy_policy_wrapper {
  display: flex;
  align-items: center;
  min-height: 100dvh;
  flex-direction: column;
  width: 70%;
  margin: 0 auto;
  margin-top: 10dvh;
  text-align: center;
  gap: 1rem;
  font-family: "Poppins", sans-serif;
}

.privacy_policy_wrapper a {
  color: var(--accent-secondary-blue);
  text-decoration: none;
}
.privacy_policy_wrapper a:hover {
  color: var(--accent-secondary-pink);
}
.main_heading {
  font-size: 40px !important;
}

.main_secondary_heading {
  color: var(--accent-secondary-blue);
  font-weight: 600 !important;
}

.svg_thumbsup {
  color: var(--accent-secondary-blue);
}

.privacy_policy_wrapper > div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: left;
  width: 100%;
}

.privacy_policy_wrapper h2 {
  font-size: unset !important;
  color: var(--accent-secondary-blue);
  /* border-bottom: 2px solid var(--accent-secondary-blue); */
  width: fit-content;
  display: flex;
  flex-direction: column;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

.privacy_policy_wrapper div section h2 {
  gap: 1rem;
}
.definitions_img {
  width: 40px !important;
}

.privacy_policy_wrapper > div section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.privacy_policy_wrapper > div section ul {
  margin-top: 1rem;
}
.privacy_policy_wrapper > div ul li::marker,
.privacy_policy_wrapper > div section ul li::marker {
  color: gray;
}
.privacy_policy_wrapper > div section ul {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.privacy_policy_wrapper > div section ul li b {
  color: var(--accent-secondary-pink);
  font-weight: 500;
}

.listing_parent b {
  color: var(--accent-secondary-yellow);
  font-weight: 500;
}

.privacy_policy_wrapper img {
  width: 50px;
  aspect-ratio: 1/1;
}

#different_img {
  width: 70px;
}

.end_of_page {
  width: 70% !important;
  height: 4px;
  background-color: rgb(218, 218, 218);
  margin-top: 3dvh;
  margin-bottom: 7dvh;
  border-radius: 10px;
}

@media all and (max-width: 600px) {
  .privacy_policy_wrapper {
    width: 85%;
  }
  .privacy_policy_wrapper,
  .privacy_policy_wrapper > div {
    text-align: center;
  }

  .privacy_policy_wrapper h2 {
    margin: 0 auto;
    justify-content: center;
    align-items: center;
  }

  .privacy_policy_wrapper ul {
    list-style: none;
    padding-left: 0;
  }
}
