.news-container {
    background-color: #f4f7fa;
    padding: 30px 0;
  }
  
  .news-card1 {
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .news-thumbnail {
    height: 300px;
    object-fit: cover;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  
  .news-body {
    padding: 20px;
    background-color: #fff;
  }
  
  .news-title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 15px;
    color: #2c3e50;
  }
  
  .news-description {
    font-size: 1.1rem;
    color: #34495e;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .news-description p {
    margin-bottom: 15px;
  }
  
  .news-description a {
    color: #2980b9;
    text-decoration: none;
  }
  
  .read-more-btn {
    background-color: #2980b9;
    border: none;
    color: white;
    font-size: 1rem;
    padding: 10px 20px;
    border-radius: 30px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .read-more-btn:hover {
    background-color: #1f6a8d;
  }
  
  .sidebar {
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  }
  
  .sidebar-content h4 {
    font-size: 1.5rem;
    color: #2c3e50;
    margin-bottom: 15px;
  }
  
  .sidebar-content ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar-content ul li {
    margin-bottom: 10px;
  }
  
  .sidebar-content ul li a {
    color: #2980b9;
    text-decoration: none;
    font-size: 1rem;
  }
  
  .sidebar-content ul li a:hover {
    text-decoration: underline;
  }
  
  .loading {
    font-size: 1.5rem;
    color: #2980b9;
    text-align: center;
    margin-top: 50px;
  }
  