.instagram-main {
  background-color: var(--primary-light);
  z-index: 100;
}

.instagram-main h2 {
  font-size: calc(var(--lrg-size) - 20px) !important;
  color: var(--primary-dark);
  font-weight: 700 !important;
  margin: 50px auto 25px auto !important;
  width: 85%;
}

.instagram-container {
  padding: 0 50px;
  margin-top: 35px;
}


.instagram-post {
  background-color: white;
  border: 1px solid #dbdbdb;
  border-radius: 12px;
  width: 32%;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.instagram-post:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.instagram-header {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
}

.instagram-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.instagram-username {
  font-weight: 600 !important;
  font-size: 14px;
}

.instagram-image {
  width: 100%;
  height: auto;
  display: block;
}

.instagram-caption {
  padding: 10px;
  font-size: 14px;
  color: #262626;
  line-height: 1.4;
  white-space: pre-wrap;
}

@media (max-width: 900px) {
  .instagram-post {
    width: 47%;
  }
}

@media (max-width: 480px) {
  .instagram-caption {
    font-size: 12px;
  }
}

.lightwidget-widget {
  height: 100vh;
}

@media (max-width: 1024px) {
  .lightwidget-widget {
    height: 85vh;
  }
}

@media (max-width: 900px) {
  .lightwidget-widget {
    height: 70vh;
  }
}

@media (max-width: 600px) {
  .lightwidget-widget {
    height: 65vh;
  }
}

@media (max-width: 450px) {
  .lightwidget-widget {
    height: 80vh;
  }
}

@media all and (max-width: 550px) {
  .instagram-main h2 {
    text-align: start !important;
    font-size: calc(var(--lrg-size) - 25px) !important;
    margin-bottom: 10px !important;
  }

  .instagram-post {
    width: 100%;
  }
}
