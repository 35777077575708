.searchbar_container {
  background-color: var(--primary-dark);
  color: white;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.55);
  grid-row: 1/3;
  display: flex;
  gap: 30px;
  flex-direction: column;
}

.filter {
  display: none;
}
.search_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search_body {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: -webkit-fill-available;
  font-size: clamp(1rem, -0.5rem + 2vw, 1.1rem);
    max-height: calc(100dvh - 220px);
    overflow-y: scroll;
}

.search_body::-webkit-scrollbar{
  display: none;
}
.location_filtering,
.type_filtering {
  display: flex;
  flex-direction: column;
}

.filtering {
  position: relative;
  width: 100%;
  display: flex;
  color: black;
}

.filtering span {
  padding: 8px 12px;
  border: 1px solid #ccc;
  cursor: pointer;
  width: 100%;
  color: white;
  display: flex;
  border-radius: 10px;
  justify-content: space-between;
}

.filtering_open span {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border: 1px solid var(--accent-blue);
}

.cancel_button {
  display: none;
}

.filtering ul {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.filtering ul li {
  padding: 8px 12px;
  cursor: pointer;
}

.filtering ul li:is(:hover, :focus) {
  background-color: var(--accent-blue);
  color: white;
  outline: none;
}

.filtering_open ul {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid var(--accent-blue);
  border-top: none;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: block;
  z-index: 100;
}

.filtering ul {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}


.type_title,
.price_title,
.bedrooms_title,
.space_title {
  margin: 0;
  padding-bottom: 10px;
  font-weight: 600!important;
}

.bedrooms_title {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.minmax_wrapper {
  display: flex;
  gap: 20px;
}

.minmax_wrapper input {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  display: flex;
  width: 100%;
  padding: 3px 8px;
  outline: none;
  border: 1px solid lightgray;
}
.minmax_wrapper input:focus {
  border: 1px solid var(--accent-blue);
  color: var(--accent-blue);
}

.input_parent input:focus + i {
  color: var(--accent-blue);
}

.input_parent input:focus + span {
  color: var(--accent-pink);
}

.space_container .minmax_wrapper input:focus {
  border: 1px solid var(--accent-pink);
  color: var(--accent-pink);
}

.space_container .input_parent input:focus + i {
  color: var(--accent-pink);
}

.minmax_wrapper input::-webkit-outer-spin-button,
.minmax_wrapper input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  appearance: none;
}

.input_parent {
  position: relative;
}

.input_parent i,
.input_parent span {
  z-index: 10;
  background-color: white;
  padding-inline: 6px;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  color: rgb(173, 173, 173);
  font-size: 11px;
}

.input_parent span {
  font-size: 13px;
}

.bedrooms_count {
  display: flex;
  width: 30%;
  justify-content: space-between;
  align-items: center;
}

.bedrooms_count p {
  font-size: 18px;
}

.bedrooms_count p {
  margin: 0;
}

.bedrooms_count p span {
  margin-top: -7px;
  display: flex;
}

.bedrooms_count button {
  cursor: pointer;
  width: 30px;
  height: 30px;
  outline: none;
  border: none;
  border-radius: 7px;
  color: white;
}

.bedrooms_count button:first-of-type {
  background-color: var(--accent-blue);
}

.bedrooms_count button:last-of-type {
  background-color: var(--accent-pink);
}

.chck_box_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-row-gap: 20px;
}
.chck_box {
  display: flex;
  gap: 20px;
}

.clear_button {
  outline: none;
  border: 1px solid rgb(185, 185, 185);
  color: rgb(185, 185, 185);
  background: none;
  padding: 5px 10px;
  border-radius: 7px;
  cursor: pointer;
}

.clear_button:hover {
  border-color: var(--accent-blue);
  color: var(--accent-blue);
}
.search_button {
  margin-top: auto;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  border: none;
  background-color: var(--accent-blue);
  color: white;
  font-size: 18px;
  cursor: pointer;
  transition: 0.1s all ease;
}

.search_button:is(:hover, :focus) {
  background-color: #517d8b;
}

.main_span:hover {
  color: var(--accent-blue);
}

.main_span:hover > i {
  color: var(--accent-blue);
}

.main_span i {
  line-height: unset;
}
.search_body input {
  border-radius: 8px;
}

.bedroom-selector {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.bedroom-option {
  padding: 4px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  border-radius: 5px;
  cursor: pointer;
  color: black;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.bedroom-option.selected {
  background-color: var(--accent-blue);
  box-shadow: 0 0 10px rgba(43, 177, 255, 0.8);
  color: white;
}

.options_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.options_container .bedroom-option {
  width: fit-content !important;
  padding-inline: 10px;
}

.options_container .bedroom-option.selected {
  background-color: var(--accent-pink);
  box-shadow: 0 0 10px rgba(255, 52, 153, 0.8);
}
.bedroom-option::selection {
  background: none;
  color: none;
}
.checkbox_cntainer {
  display: flex;
  gap: 10px;
}

.checkbox_cntainer label {
  display: flex;
  gap: 5px;
}
@media all and (max-width: 900px) {
  .filter {
    display: block;
    padding: 10px;
    font-size: 18px;
    background-color: var(--accent-blue);
    border: none;
    border-radius: 5px;
    color: white;
    max-height: 60px;
  }

  .minmax_wrapper {
    flex-direction: column;
  }
  .search_header {
    padding-bottom: 7px;
  }
  .search_header h3{
    font-weight: 600!important;
    margin: 0;
  }

  .searchbar_container {
    display: none;
  }
  .searchbar_container.open {
    display: block;
    position: absolute;
    z-index: 100;
    inset: 0;
    background-color: var(--primary-dark);
    top: 110px;
    height: 86vh;
  }
  .search_body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-height: calc(100dvh - 180px);
  }

  .search_body > * {
    flex-shrink: 0;
    scroll-snap-align: center;
  }

  .search_body h4 {
    font-size: clamp(1rem, 0.8406rem + 0.7971vw, 1.6875rem);
    font-size: clamp(0.625rem, 0.3786rem + 1.2319vw, 1.6875rem);
  }
  .accessibility_title {
    margin: 0;
    padding-bottom: 10px;
  }

  .bedrooms_count {
    width: 100px;
  }

  .minmax_wrapper input {
    width: 100%;
    padding: 1.6%;
  }

  .cancel_button {
    display: block;
    padding: 10px;
    border-radius: 5px;
    outline: none;
    border: 2px solid gray;
    background-color: white;
    color: gray;
    font-size: 18px;
    cursor: pointer;
    transition: 0.1s all ease;
  }

  .search_button {
    margin-top: unset;
  }
}
