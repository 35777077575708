body {
  margin: 0;
  font-family: "Playfair Display", "Open Sans", serif, sans-serif !important;
  background-color: white;
}

html {
  scroll-behavior: unset !important;
}
body h1 {
  font-size: var(--lrg-size);
}

body a {
  text-decoration: none;
}

body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin-top: unset!important;
  margin-bottom: unset!important;
  font-weight: revert!important;
  font-size: revert!important;
  line-height: unset!important;
  color: unset;
}

body p{
  margin-top: unset!important;
    margin-bottom: unset!important;
}


@media (min-width: 1200px)
{
  body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin-top: unset!important;
  margin-bottom: unset!important;
  font-weight: revert!important;
  font-size: revert!important;
  line-height: unset!important;
  color: unset;
}
}
.users-page-header-container .label i{
  right: -20px;
}


@media all and (max-width:900px) {
  .aboutus .aboutus-background h1{
    font-size: 40px!important;
   margin-top: 50px!important;
 }
}