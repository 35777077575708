.not_found_main{
  font-family: Arial, Helvetica, sans-serif;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-dark);
  color: white;
  position: relative;
}


.content_notfound{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.content_wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 5px;
  box-shadow: rgba(255, 255, 255, 0.3) 0px 0px 6px 0px, rgba(250, 250, 250, 0.15) 0px 2px 6px 2px;
}

.content_wrapper button{
  cursor: pointer;
  outline: none;
  background-color: white;
  border: none;
  padding: 10px 14px;
  font-size: 16px;
  border-radius: 5px;
  color: rgb(68, 68, 68);
}

.content_notfound > *{
  margin: 0;
  height: fit-content;
}

.content_notfound h1{
  font-size: 50px;
  font-weight: 500;
}


.content_notfound p{
  font-size: 23px;
}

.error_divider{
  height: 80px;
  width: 2px;
  background-color: white;
}

@media all and (max-width:550px) {
  .content_notfound{
    padding: 0;
    gap: 10px;
  }

  .error_divider{
    height: 60px;
  }
  .content_notfound h1{
    font-size: 25px;
  }
  
  .content_notfound p{
    font-size: 16px;
  }
}