.singlepage-classname-singlepage {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px;
  padding: 20px;
}

.singlepage-classname-left {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.singlepage-classname-image {
  width: 100%;
  height: 550px;
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.singlepage-classname-description {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.singlepage-classname-line {
  height: 40px;
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.singlepage-classname-title {
  width: 60%;
  height: 40px;
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.singlepage-classname-data {
  height: 110px;
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.singlepage-classname-subtitle {
  width: 40%;
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.singlepage-classname-text {
  width: 100%;
  height: 10px;
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.singlepage-classname-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.singlepage-classname-detail {
  height: 30px;
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.singlepage-classname-buttons {
  display: flex;
  gap: 10px;
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.singlepage-classname-button {
  width: 100px;
  height: 40px;
  border-radius: 5px;
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .singlepage-classname-singlepage {
    grid-template-columns: 1fr;
    padding: 50px;
  }

  .singlepage-classname-left {
    flex-direction: row;
    justify-content: center;
  }

  .singlepage-classname-image {
    height: 450px;
  }

  .singlepage-classname-description {
    gap: 10px;
  }

  .singlepage-classname-title,
  .singlepage-classname-subtitle {
    width: 100%;
  }

  .singlepage-classname-text {
    height: 8px;
  }

  .singlepage-classname-details {
    gap: 10px;
  }

  .singlepage-classname-detail {
    width: 100%;
  }

  .singlepage-classname-buttons {
    flex-direction: column;
    gap: 15px;
  }

  .singlepage-classname-button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .singlepage-classname-singlepage {
    padding: 50px;
  }

  .singlepage-classname-left {
    flex-direction: column;
    align-items: center;
  }

  .singlepage-classname-image {
    height: 450px;
  }

  .singlepage-classname-description {
    gap: 8px;
  }

  .singlepage-classname-title,
  .singlepage-classname-subtitle,
  .singlepage-classname-text,
  .singlepage-classname-detail {
    width: 100%;
  }

  .singlepage-classname-buttons {
    gap: 10px;
  }
}
