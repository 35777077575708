/* Skeleton Loader */
.skeleton {
    animation: shimmer 1.5s infinite linear;
    background: linear-gradient(90deg, #e0e0e0 25%, #b0bac2 50%, #e0e0e0 75%);
    background-size: 200% 100%;
  }
  
  .skeleton-wrapper {
    width: 85%;
    margin: 0 auto;
    margin-top: 5dvh;
    min-height: 100dvh;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    border-radius: 50px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    overflow: hidden;
    padding: 20px;
    background-color: #f5f5f5; /* Added a light gray background for a darker look */
  }
  
  .skeleton-header {
    height: 50px;
    width: 60%;
    margin: 20px auto;
    border-radius: 8px;
    background-color: #e0e0e0; /* Changed background color for a darker look */
  }
  
  .skeleton-carousel {
    height: 40dvh;
    width: 100%;
    margin-top: 20px;
    border-radius: 10px;
    background-color: #e0e0e0; /* Changed background color for a darker look */
  }
  
  .skeleton-description {
    width: 90%;
    margin: 8px auto;
    height: 20px; /* Increased the height of the description */
    border-radius: 5px;
    background-color: #e0e0e0; /* Changed background color for a darker look */
  }
  
  .skeleton-features {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    margin-top: 40px;
  }
  
  .skeleton-feature {
    width: 350px;
    height: 19dvh;
    border-radius: 10px;
    background-color: #e0e0e0; /* Changed background color for a darker look */
  }
  
  @keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }