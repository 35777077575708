header {
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 63px;
  color: white;
  transition: 0.2s all ease;
}

.shanty {
  position: sticky;
  z-index: 800;
  top: 0;
}

.graynav {
  background-color: var(--primary-dark);
  height: 100px !important;
}
.bg_nav {
  background-color: #0A3D62;
  transition: 0.2s all ease;
  box-shadow: 0px 4px 20px -5px rgba(0, 0, 0, 0.25);
}
header ul {
  width: 70%;
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin-top: 5px;
}

.nav_logo {
  height: 90px;
  cursor: pointer;
}

.ul_lists_wrapper {
  position: unset;
  height: fit-content;
  display: flex;
  width: calc(50% + 100px);
  justify-content: space-evenly;
  gap: 20px;
}

.ul_lists_wrapper li {
  list-style: none;
  cursor: pointer;
  position: relative;
  width: fit-content;
}

.ul_lists_wrapper li a {
  text-decoration: none;
  color: white;
}

.ul_lists_wrapper li a:is(:focus, :hover) ~ .drp_nav_list{
display: flex;
}
.ul_lists_wrapper li a::before {
  content: "";
  position: absolute;
  width: 0;
  height: 1px;
  background-color: white;
  top: calc(100% + 4px);
  transition: 0.3s all ease;
}

.ul_lists_wrapper li a:is(:hover, :focus) {
  outline: none;
  border: none;
}
.ul_lists_wrapper li a:is(:hover, :focus-visible)::before {
  width: 100%;
}

.ul_lists_wrapper li:nth-of-type(2) a:is(:hover, :focus-visible)::before {
  width: 0%;
}

li i {
  font-size: 24px;
}

li p i {
  font-size: 24px;
  margin-right: 14px;
}

.nav_btns_wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.nav_btns_wrapper button {
  background: transparent;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  border: 2px solid;
  font-family: "Open Sans", sans-serif;
}

.nav_btns_wrapper button:first-of-type {
  border-color: var(--primary-light);
  color: var(--primary-light);
}

.brgr_icn {
  display: none;
  cursor: pointer;
}

.drp_nav {
  position: relative;
  height: fit-content;
}

/* .drp_nav_list {
  background-color: var(--primary-dark);
  border: 1px solid black;
  padding: 5px;
  box-shadow: 0px 0px 10px rgb(255 255 255 / 25%);
  opacity: 0;
  display: none;
  position: absolute;
  top: 100%;
  margin-top: 2px;
  width: 100%;
  gap: 10px;
  height: 110px!important;
  flex-direction: column;
  transition: 0.2s 0.2s all ease;
   border-bottom-left-radius: 5px;
   border-bottom-right-radius: 5px;
}

.drp_nav_list:is(:hover, :focus){
  display: flex;
  opacity: 1;
    z-index: 99;
    align-items: flex-start;
} */

/* Updated CSS for .drp_nav_list */
.drp_nav_list {
  background-color: var(--primary-light); /* Matching the theme color */
  border: none;
  border-radius: 8px;
  padding: 10px 15px 0 10px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
  opacity: 0;
  display: none;
  position: absolute;
  top: 100%;
  margin-top: 10px;
  width: 150px;
  flex-direction: column;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translateY(-10px);
  z-index: 99;
  font-family: "Roboto", sans-serif;
}

/* Ensure it appears smoothly */
.drp_nav:is(:hover, :focus) .drp_nav_list {
  opacity: 1;
  display: flex;
  transform: translateY(0);
}

/* Individual links styling */
.drp_nav_list a {
  text-decoration: none;
  color: var(--text-primary); /* Theme-based text color */
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 14px;
  font-weight: 500;
}

.drp_nav_list a:hover {
  background-color: var(--primary-dark);
  color: white !important;
}

/* Subtle divider between items */
.drp_nav_list a:not(:last-of-type) {
  margin-bottom: 8px;
}

/* Optional hover effect for the dropdown container */
.drp_nav_list:hover {
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
}

/* Add a small arrow pointing to the dropdown */
.drp_nav_list::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 20px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid var(--primary-light);
  z-index: 100;
}

.drp_nav_list a {
  color: var(--primary-dark) !important;
}

/* Mobile adjustments */
@media all and (max-width: 900px) {
  .drp_nav_list {
    width: 90%;
    left: 100%;
    top: -50%;
    margin-top: 0;
    transform: translateY(0);
  }

  .drp_nav_list::before {
    display: none;
  }
}

.for_bfr {
  position: relative;
  top: -110px;
  z-index: -1;
  height: 20px;
  width: 100%;
}
.for_bfr::before {
  content: "";
  position: absolute;
  height: 20px;
  width: 100%;
  z-index: -30;
  inset: 0;
  top: -10px;
}

.drp_nav:is(:hover, :focus){
  outline: none;
}
.drp_nav:is(:hover, :focus) .drp_nav_list {
  opacity: 1;
  display: flex;
  z-index: 99;
  height: fit-content;
  align-items: flex-start;
}
/* .drp_nav_list a:hover::before {
  display: none;
}

.drp_nav_list a:first-of-type:hover {
  color: var(--accent-blue);
}

.drp_nav_list a:nth-of-type(2):hover {
  color: var(--accent-pink);
}

.drp_nav_list a:last-of-type:hover {
  color: var(--accent-yellow);
} */
@media all and (max-width: 900px) {
  .brgr_icn {
    display: block;
    position: relative;
    z-index: 899;
  }

  .menu-container {
    display: flex;
    height: 40px;
    align-items: center;
    gap: 20px;
    width: 130px;
  }

  .mob-logo {
    position: absolute;
    left: 0;
    height: 150px;
    top: 30px;
    z-index: 899;
  }

  .brgr_icn i {
    font-size: 40px;
  }
  header {
    height: 90px;
    padding-inline: 20px;
  }
  header ul {
    position: absolute;
    background-color: var(--primary-dark);
    flex-direction: column;
    justify-content: center;
    top: 0;
    margin-top: 5px;
    width: 100%;
    left: -100%;
    transition: 0.3s all ease;
    gap: 50px;
    height: 100dvh;
    overflow: scroll;
  }

  .ul_lists_wrapper {
    flex-direction: column;
    width: 100%;
    align-items: start;
    margin-left: 100px;
    margin-top: 50px;
    overflow: hidden;
    gap: 50px;
  }

  .nav_btns_wrapper {
    gap: 50px;
    margin-top: 20px;
  }

  .nav_logo {
    height: 100px;
  }

  .menu_left {
    overflow: hidden;
    left: 0;
  }
}

@media all and (max-width: 900px) and (orientation: landscape) {
  .ul_lists_wrapper {
    gap: 40px;
  }

  header ul {
    gap: 40px;
  }

  /* .drp_nav_list{
    left: calc(100% + 10px);
    margin-top: -20px;
  } */

  .for_bfr{
    left: -30px;
    top: 0;
  }

  .for_bfr::before{
    width: 40%;
    height: 100px;
    margin-top: -100px;
  }
}


@media all and (max-width: 900px) {
  /* .drp_nav_list{
    left: calc(100% + 10px);
    margin-top: -20px;
  } */

  .for_bfr{
    left: -30px;
    top: 0;
  }

  .for_bfr::before{
    width: 40%;
    height: 100px;
    margin-top: -110px;
  }
}