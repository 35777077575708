.buy-page-parent{
    width: 100%;
    height: 100%;
    background-color: white;
}
.property-page-container{
    margin: 2% 2%;
    display: flex;
    flex-direction: column;
}
  /* .dropdown-container{
    width: 20%;
  } */
  .no-cards{
    display: flex;
    width: 100%;
    height: 700px;
    margin-top:10%;
    justify-content: center;
    font-size: 24px;
  }
  .main-span-sub{
    color:  black !important;
  }
  .main_span{
    display: flex;
  }
  #property-file-input{
    border-color: black;
  }
  .edit-form-image{
    width: 75px;
    height: 75px;
  }
  .images-parent{
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    background-color: gray;
    gap: 17px;
    min-height: 120px;
    max-height: 200px;
    overflow-y: scroll;
  }

  .images-parent::-webkit-scrollbar{
    display: none;
  }
  .image-dlt-icon{
    height: 20px;
    width: 20px;
    position: absolute;
    right: 5px;
    top: 0;
    &:hover{
      cursor: pointer;
    }
  }
  .card-layer{
    position: relative;
    &:hover{
      .delete-button{
        display: block;
      }
      .edit-button{
        display: block;
      }
    }
  }

  .delete-button{
    display: none;
    z-index: 300;
    width: 32px;
    height: 32px;
position:absolute;
right: 10px;
top: 10px;
&:hover{
  border-radius: 8px;
  cursor: pointer;
  scale: 1.03;
  background-color: rgb(59, 53, 46);
}
}
.edit-button{
  display: none;
  z-index: 300;
  width: 32px;
  height: 32px;
position:absolute;
right: 50px;
top: 10px;
&:hover{
border-radius: 8px;
cursor: pointer;
scale: 1.03;
background-color: rgb(59, 53, 46);
}
}
.edit-image-container{
  position: relative;
}