.hero_parent {
  height: 100dvh;
  position: fixed;
  top: 0;
  width: 100%;
}
#hero {
  position: relative;
  height: 100%;
  width: 100%;
}

.hero_text_area {
  position: absolute;
  z-index: 300;
  inset: 0;
  background: rgba(0, 0, 0, 0.50);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  color: white;
}

.hero_text_area h1 {
  text-align: center;
  padding: 10px;
  font-size: var(--lrg-size)!important;
  font-weight: bold;
}

.hero_text_area p {
  margin: 0;
  max-width: 55vh;
  text-align: center;
  font-size: var(--mdm-L-size);
}


.hero_text_area .button {
  background: none;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  padding: 13px 20px;
  font-size: var(--mdm-size);
  border-radius: 100px;
  font-family: "Open Sans", sans-serif;
  cursor: pointer;
}
.carousel {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 100;
}

.img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img.active {
  clip-path: circle(150% at 100% 100%);
  animation: circled 1s linear;
  z-index: 10 !important;
}

@keyframes circled {
  0% {
    clip-path: circle(0% at 100% 100%);
  }

  100% {
    clip-path: circle(150% at 100% 100%);
  }
}
@media all and (max-width: 510px) {
  .hero_text_area h1 {
    font-size: 25px !important;
    /* padding: 10px; */
  }

  .hero_text_area p {
    padding: 20px;
    font-size: 5vw !important;
  }

  .hero_text_area {
    gap: 0;
  }
}

@media all and (min-width: 511px) and (max-width: 900px) {
  .hero_text_area {
    gap: 10px;
  }

  .hero_text_area h1 {
    /* max-width: 90%; */
    font-size: 40px !important;
    margin-top: 50px;
  }

  .hero_text_area p {
    max-width: 70%;
  }
}