.users-page-parent{
    width: 100%;
    height: 100%;
    background-color: white;
}
.users-page-container{
    margin: 2% 4%;
    display: flex;
    flex-direction: column;
}
.users-page-title{
    color: black;
    font-size: 32px;
}
.users-page-header-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.users-table-container{
    min-height: 400px;
}
