.partners-main {
  background-color: var(--primary-light);
  z-index: 100;
  overflow: hidden;
}

.partners-main h2 {
  font-size: calc(var(--lrg-size) - 20px) !important;
  color: var(--primary-dark);
  font-weight: 700 !important;
  margin: 80px auto 40px auto !important;
  width: 85%;
}

.partners-main {
  z-index: 100;
}

.partners-heading {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #2a2a2a;
}

.partner-logo {
  width: 120px;
  height: 120px;
  object-fit: contain;
  border-radius: 50%;
  border: 2px solid #ddd;
  transition: transform 0.3s ease-in-out;
}

.partner-logo:hover {
  transform: scale(1.1);
}

.skeleton-logos {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.skeleton-logo {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #f0f0f0;
  animation: skeleton-loading 1.5s infinite ease-in-out;
}

@keyframes skeleton-loading {
  0% {
    background-color: #f0f0f0;
  }
  50% {
    background-color: #e0e0e0;
  }
  100% {
    background-color: #f0f0f0;
  }
}

@media all and (max-width: 550px) {
  .partners-main h2 {
    text-align: start !important;
    font-size: calc(var(--lrg-size) - 25px) !important;
    margin-bottom: 50px !important;
  }
}

@media (max-width: 768px) {
  .partner-logo {
    width: 80px;
    height: 80px;
  }
  .skeleton-logo {
    width: 80px;
    height: 80px;
  }
}
